import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { AuthProvider } from './AuthContext'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons' //Brands
import { fat } from '@fortawesome/pro-thin-svg-icons' //Regular
import { fas } from '@fortawesome/pro-solid-svg-icons' //Regular
import { far } from '@fortawesome/pro-regular-svg-icons' //Regular
import { fal } from '@fortawesome/pro-light-svg-icons' //Light
import { fad } from '@fortawesome/pro-duotone-svg-icons' //Duotone
library.add(fas, fab, far, fal, fad, fat)
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// const Home = React.lazy(() => import('./views/home/Home'))

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const PageSignIn = React.lazy(() => import('./views/pages/pageSignIn/PageSignIn'))
// const Register = React.lazy(() => import('./views/pages/PageRegister/Register'))
const RegisterForDoctor = React.lazy(() => import('./views/pages/PageRegister/RegisterForDoctor'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const PageWelcome = React.lazy(() => import('./views/pages/pageWelcome/pageWelcome'))

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route element={<Page404 />} exact name="Page 404" path="/404" />
            <Route element={<Page500 />} exact name="Page 500" path="/500" />
            <Route element={<PageSignIn />} exact name="Login Page" path="/signIn" />
            <Route element={<PageWelcome />} exact name="Welcome" path="/welcome" />
            <Route element={<RegisterForDoctor />} exact name="Register Page" path="/register" />
            <Route element={<DefaultLayout />} name="Home" path="*" />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
  )
}
export default App
