import React, { createContext, useContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  console.log('AuthProvider', currentUser)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        Auth.currentSession().then((user) => {
          const { payload } = user.getIdToken()
          if (payload && payload['cognito:groups']) {
            console.log('cognito:groups', payload['cognito:groups'])
          }
        })
        setCurrentUser(user)
      } catch (error) {
        setCurrentUser(null)
      }
    }
    fetchUser()
  }, [])

  /**
   * 認証コードの送信
   * @param {string} username
   * @param {string} code
   */
  const confirmSignUp = async (username, code) => {
    try {
      const callback = await Auth.confirmSignUp(username, code)
      return callback
    } catch (error) {
      console.log('error confirming sign up', error)
      throw error
    }
  }

  /**
   * 認証コード送信
   * @param {string} username
   */
  const resendSignUp = async (username) => {
    try {
      const codeDeliveryDetails = await Auth.resendSignUp(username)
      console.log('resendSignUp successfully')
      return codeDeliveryDetails
    } catch (error) {
      console.log('resendSignUp Error: ', error)
      throw error
    }
  }

  const signUp = async (username, password, email, attributes) => {
    try {
      Auth.configure()
      const user = await Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      })
      setCurrentUser(user)
    } catch (error) {
      console.log('error signing up:', error)
    }
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   * @returns CognitoUser
   */
  const signIn = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password, {
        mfaType: 'SMS',
      })
      return user
    } catch (error) {
      console.log('signIn Error', error)
      throw error
    }
  }

  const confirmSignIn = async (email, code) => {
    try {
      const user = await Auth.confirmSignIn(email, code, 'SMS_MFA')
      console.log('confirmSignIn:user', user)
      return user
    } catch (error) {
      console.error('Code verification failed:', error)
      return error
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut()
      setCurrentUser(null)
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  const value = {
    currentUser,
    setCurrentUser,
    signIn,
    signUp,
    logout,
    resendSignUp,
    confirmSignUp,
    confirmSignIn,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
AuthProvider.propTypes = {
  children: PropTypes.node,
}
